'use strict';

// INICIAL
$(window).on('load', function () { // makes sure the whole site is loaded
    $('#preloader').delay(100).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(100);
    
    $('#page-loader').addClass('.preloader').delay(350).fadeOut('slow', function () {
        document.body.classList.remove('loading');
    });
});

$(window).scroll(function() {     
    var scroll = $(window).scrollTop();
    if (scroll > 10) {
        $("#header").addClass("shadow");
        $("img").removeClass("grey"); 
    }
    else {
        $("#header").removeClass("shadow");
        $("img").addClass("grey"); 
    }
});


$(function () {

  $('.main-gallery').flickity({
    lazyLoad: true,
    freeScroll: true,
    cellAlign: 'left',
    freeScrollFriction: 0.03,
    prevNextButtons: false,
    contain: true,
    wrapAround: false,
    pageDots: true,
    reloadOnUpdate: false,
    draggable: true,
  });

    /* SCROLL */
    var scrollTop = $(".scrollTop");

    $(scrollTop).on('click', function() {
        $('html, body').animate({
        scrollTop: 0
        }, 1200);
        return false;
    });

    /* CAROUSEL */
    $(".carousel-cell img").hover(
        function () {
            // changing the color
            $(".caption").text($(this).attr("alt")).show();
            $(".hide-hover").hide();
        },

        function () {
            // Putting the color back
            $(".caption").text($(this).attr("alt")).hide();
            $(".hide-hover").show();
        });

    /* DINAMIC BG */
    function bgChanger(){
		if(this.scrollY>this.innerHeight/2.5){
		  document.body.classList.add("bg-active");
		}
		else{
		  document.body.classList.remove("bg-active");
		}
	  }
	  
	  window.addEventListener("scroll",bgChanger);

    /* HORIZONTAL SCROLL */
    const horizontals = document.querySelectorAll('.section--horizontal');

    // For each horizontal scroll element, apply the effect
    horizontals.forEach(function (horizontal) {

        // Get the inner element
        const inner = horizontal.querySelector('.section__inner');

        // When the user scroll and an animation frame is available
        window.addEventListener('scroll', function () {
            window.requestAnimationFrame(function () {

                // The distance to scroll inside the horizontal element
                // is its height - the window's height
                const toGo = horizontal.offsetHeight - window.innerHeight;

                // The scroll position inside the element
                // is the scroll position - the element's distance from the top
                const position = window.scrollY - horizontal.offsetTop;

                // The progression between 0 & 1 is the scroll position
                // inside the element divided by the distance to scroll
                const progression = (position / toGo);

                // If progression is between 0 & 1 that means we are viewing
                // the section so fix it
                if (progression > 0 && progression < 1) {
                    horizontal.classList.add('section--isFixed');
                } else { // Don't fix it
                    horizontal.classList.remove('section--isFixed');
                }

                // If the progression is above 1 that means the
                // section has been completly scrolled
                if (progression >= 1) {
                    horizontal.classList.add('section--isScrolled');
                } else {
                    horizontal.classList.remove('section--isScrolled');
                }

                // Set the translation for the element
                setTranslateX(inner, progression);
            });
        });
    });

    function setTranslateX(element, progression) {
        // Limit the progression factor between 0 & 1
        if (progression > 1) {
            progression = 1;
        } else if (progression < 0) {
            progression = 0;
        }

        // The size to move is the element width minus the window width
        const toMove = element.offsetWidth - window.innerWidth;

        // The transform factor is the size to move multiplied by the progression
        const transform = (-1 * toMove * progression) + 'px';
        element.style.transform = 'translateX(' + transform + ')';
    }

    $(document).on('scroll', function () {
        $('.example-left').css("left", Math.max(500 - 0.55 * window.scrollY, -500) + "px");
    });


    /*Animations*/
    const callback = function (entries) {
        entries.forEach((entry) => {
      
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fadeIn");
          } else {
            entry.target.classList.remove();
          }
        });
      };
      
      const observer = new IntersectionObserver(callback);
      
      const targets = document.querySelectorAll(".js-show-on-scroll");
      targets.forEach(function (target) {
        target.classList.add("opacity-0");
        observer.observe(target);
      });



const menuToggler = document.querySelector('#menu-toggler');
const menuBg = document.querySelector('#menu-bg');
const menuContainer = document.querySelector('#menu-contain');

const tl = new TimelineMax();
tl.pause()
  .to('#menu-bg', {attr: { viewBox: "0 0 100 100"},  ease: Power1.easeOut})
  .to('#menu-bg-path', .3, {attr: { d: "M0 0 L100 0 L100 90 Q50 100 0 50 Z"}, ease: Power1.easeIn})
  .to('#menu-bg-path', .3, {attr: { d: "M0 0 L100 0 L100 100 Q50 100 0 100 Z"},  ease: Power1.easeOut})
  .add(containerToggle)
  .staggerFrom('.menu-list a', .2, { y: '100%' }, .1)

menuToggler.addEventListener('click', toggleMenu);

function containerToggle() {
    if (!menuContainer.classList.contains('open')) {
        menuContainer.classList.add('open');
        document.body.classList.add("fixed-position");
        $(".circle-oval").addClass('hide-map');
        $(".toggle-text").addClass('hide-map');
        $("#header").removeClass('shadow');
      } else {
        menuContainer.classList.remove('open');
        document.body.classList.remove("fixed-position");
        $(".circle-oval").removeClass('hide-map');
        $(".toggle-text").removeClass('hide-map');
        $("#header").removeClass('shadow');
      }
}

function toggleMenu() {
  
  if (!menuToggler.classList.contains('open')) {
    tl.play()
    menuToggler.classList.add('open');
    menuBg.classList.add('open');
    
  } else {
    tl.reverse()
    menuToggler.classList.remove('open');
    menuBg.classList.remove('open');
    
  }
  
}

$('#menu-toggler').click (function(){

    if ($(this).text() == "MENU")
    $(this).text("SAIR")
 else
    $(this).text("MENU");

  });

  $('.circle-oval-2').mouseover(function(){

    if ($(this).text() == "SABER MAIS")
    $(this).text("DOWNLOAD")
 else
    $(this).text("SABER MAIS");

  });

  $('.circle-oval-2').mouseleave(function(){

    $(this).text("SABER MAIS");

  });
      
});

$(document).ready(function() {
    $(".set > a").on("click", function() {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(this)
          .siblings(".content")
          .slideUp(200);
        $(".set > a i")
          .removeClass("fa-minus")
          .addClass("fa-plus");
      } else {
        $(".set > a i")
          .removeClass("fa-minus")
          .addClass("fa-plus");
        $(this)
          .find("i")
          .removeClass("fa-plus")
          .addClass("fa-minus");
        $(".set > a").removeClass("active");
        $(this).addClass("active");
        $(".content").slideUp(200);
        $(this)
          .siblings(".content")
          .slideDown(200);
      }
    });
  });
  